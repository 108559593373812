import React, { useEffect, useState } from 'react';
// import { useDebounce } from 'use-debounce';

import CountryCodes from '../assets/country_codes.json';

import PhoneData from './PhoneData';
import FoldersAction from './Pagination/FoldersAction';

import { Select, MenuItem, FormControl } from '@mui/material';

import { Checkbox, Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import YardIcon from '@mui/icons-material/Yard';

import LockSharpIcon from '@mui/icons-material/LockSharp';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import MarkEmailReadSharpIcon from '@mui/icons-material/MarkEmailReadSharp';
import UnsubscribeSharpIcon from '@mui/icons-material/UnsubscribeSharp';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import HelpIcon from '@mui/icons-material/Help';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaSquareXTwitter } from "react-icons/fa6";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { IoIosLogIn } from "react-icons/io";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import SpaIcon from '@mui/icons-material/Spa';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WineBarIcon from '@mui/icons-material/WineBar';
import Tooltip from '@mui/material/Tooltip';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ShareIcon from '@mui/icons-material/Share';
import BusinessIcon from '@mui/icons-material/Business';
import TagIcon from '@mui/icons-material/Tag';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import PaymentsSharpIcon from '@mui/icons-material/PaymentsSharp';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam,BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";
import { ImLocation } from "react-icons/im";
import { AiOutlineFlag } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaFolder } from "react-icons/fa";
import { IoIosWine } from "react-icons/io";
import { LiaGlassWhiskeySolid } from "react-icons/lia";
import { RiBeerLine } from "react-icons/ri";
import { PiBeerBottleFill } from "react-icons/pi";
import { FaBottleDroplet } from "react-icons/fa6";
import { FaWineBottle } from "react-icons/fa";

import LanguageIcon from '@mui/icons-material/Language';
import FolderIcon from '@mui/icons-material/Folder';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import TableRowsIcon from '@mui/icons-material/TableRows';
import StarIcon from '@mui/icons-material/Star';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FeedbackIcon from '@mui/icons-material/Feedback';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ContactsSharpIcon from '@mui/icons-material/ContactsSharp';

import ReactCountryFlag from "react-country-flag";

const label = { inputProps: { 'aria-label': 'Select distributor' } };

const iconsArray = [
    {icon: FolderIcon,name:'FolderIcon'},
    {icon: EmailIcon,name:'EmailIcon'},
    {icon: DriveFolderUploadIcon,name:'DriveFolderUploadIcon'},
    {icon: FolderSpecialIcon,name:'FolderSpecialIcon'},
    {icon: FindInPageIcon,name:'FindInPageIcon'},
    {icon: FolderOffIcon,name:'FolderOffIcon'},
    {icon: TableRowsIcon,name:'TableRowsIcon'},
    {icon: StarIcon,name:'StarIcon'},
    {icon: HourglassEmptyIcon,name:'HourglassEmptyIcon'},
    {icon: DoneIcon,name:'DoneIcon'},
    {icon: CancelIcon,name:'CancelIcon'},
    {icon: MonetizationOnIcon,name:'MonetizationOnIcon'},
    {icon: NotificationImportantIcon,name:'NotificationImportantIcon'},
    {icon: FeedbackIcon,name:'FeedbackIcon'},
    {icon: LocalPhoneIcon,name:'LocalPhoneIcon'},
    {icon: BookmarkIcon,name:'BookmarkIcon'},
]

const currenciesArray = [
    { icon: FolderIcon, name: 'DKK', code: 'DK', full_name: 'Danish krone' },
    { icon: FolderIcon, name: 'EUR', code: 'EU', full_name: 'Euro' },
    { icon: FolderIcon, name: 'GBP', code: 'GB', full_name: 'British Pound' },
    { icon: FolderIcon, name: 'PLN', code: 'PL', full_name: 'Polish Zloty' },
    { icon: FolderIcon, name: 'USD', code: 'US', full_name: 'US Dollar' },
    { icon: FolderIcon, name: 'EUR', code: 'AT', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'FI', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'CY', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'ES', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'EE', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'DE', full_name: 'Euro' },
    { icon: FolderIcon, name: 'EUR', code: 'BE', full_name: 'Euro' },
    { icon: FolderIcon, name: 'BGN', code: 'BG', full_name: 'Bulgarian lev' },
    { icon: FolderIcon, name: 'USD', code: 'US', full_name: 'US Dollar' },
    { icon: FolderIcon, name: 'USD', code: 'NZ', full_name: 'US Dollar' }, // Data in USD
    { icon: FolderIcon, name: 'CZK', code: 'CZ', full_name: 'Czech koruna' },
];

export default function DistributorNew(props) {
    const {
        item,
        id,
        chosenItems,
        setChosenItems,
        downloaded,
        viewed,
        userFolders,
        setUserFolders,
        getCookie,
        userPlan,
        openEmailStatusInfo,
        currentFolder,
        pageNumber,
        SingleDownload,
        changeBookmarkCounter,
        setModalDownload,
        currencies,
        setUserViewsIds
    } = props;

    // Mark this company as viewed
    const [companyViewed,setCompanyViewed] = useState(false);

    const [arrayFoldersItem,setArrayFoldersItem] = useState([]);
    
    // Init var to filter first load 
    // const [initLoad,setInitLoad] = useState(3);

    // Note state
    const [noteValue, setNoteValue] = useState('');
    // const [postNoteValue] = useDebounce(noteValue, 4000);

    // Bookmark value
    const [inBookmark,setInBookmark] = useState(false);

    // If User reporter innacurany
    const [reported,setReported] = useState(false);

    //State to show Importer on the first place 
    const [companyTypesArr,setCompanyTypesArr] = useState([]);

    //State to show Wine on the first place of products
    const [companyProductsArr,setCompanyProductsArr] = useState([]);

    // Open List of Types in Top Part of Company
    const [openTypes,setOpenTypes] = useState(false);

    // Open List of Products in Top Part of Company
    const [openProducts,setOpenProducts] = useState(false);

    // Wait for Types
    const [typesLoaded,setTypesLoaded] = useState(false);

    // Wait for Types
    const [productsLoaded,setProductsLoaded] = useState(false);

    // Emails for this company
    const [emails,setEmails] = useState([]);

    // Phones for this compny
    const [phones,setPhones] = useState([]);
    
    // New Type of Company Get Data
    const [companyData,setCompanyData] = useState([]);
    const [dataReceived,setDataReceived] = useState(false);

    const [facebookLink,setFacebookLink] = useState('');
    const [instagramLink,setInstagramLink] = useState('');
    const [xLink,setXLink] = useState('');

    const [openedPerson,setOpenedPerson] = useState(0);

    // Selected Currencies
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');

    // Show or Hide Branches
    const [showBranches,setShowBranches] = useState(7);

    // Show or Hide Flags 11+ in distributing in
    const [showFlags,setShowFlags] = useState(false);

    const handleChangeCurrency = (event) => {
        setSelectedCurrency(event.target.value);
    };

    useEffect(()=>{
        setCompanyViewed(viewed);
    },[viewed]);

    // Check if item is in bookmarks
    useEffect(()=>{

        // Set and clear notes
        setNoteValue(companyData?.notes?.replaceAll('&quot;','').replaceAll('&amp;','&').replaceAll('&#039;',"'").replaceAll('“',"").replaceAll('”',"").replaceAll('"',""));
        
        // Set social links
        var socialLinks = companyData?.facebook?.split(',');
        var tempFacebook = 'unknown';
        var tempInstagram = 'unknown';
        var tempX = 'unknown';
        for (let i = 0; i < socialLinks?.length; i++) {
            if (socialLinks[i].includes('facebook')) {
                tempFacebook = 'https://facebook' + socialLinks[i].split('facebook')[1];
            }
            if (socialLinks[i].includes('instagram')) {
                tempInstagram = 'https://instagram' + socialLinks[i].split('instagram')[1];
            }
            if (socialLinks[i].includes('www.x.') || socialLinks[i].includes('/x.') || socialLinks[i].includes('twitter')) {
                tempX = socialLinks[i];
            }
        }
        setFacebookLink(tempFacebook);
        setInstagramLink(tempInstagram);
        setXLink(tempX);

        if (item.bookmark === '1' && item.bookmark === 1) {
            setInBookmark(true);
        }

        // Check email statuses
        if (item.verified?.length > 0) {
            var validCounter = 0;
            var invalidCounter = 0;
            for(let v = 0;v < item.verified?.length;v++) {
                if (
                item.verified[v] === 'valid' || 
                (item.verified[v] === 'do_not_mail' && 
                item.status_details[v] !== 'global_suppression' && 
                item.status_details[v] !== 'toxic' &&
                item.status_details[v] !== 'possible_trap') 
                ) {
                    validCounter = validCounter + 1;
                } 
                if (item.verified[v] === 'invalid') {
                    invalidCounter = invalidCounter + 1;
                }
            }
        } 

        // Chech last update of email
        // if (item?.status_date?.length > 0) {
            var item_email_date = 'not available'; 
            // var list_dates_emails = [];
            var last_email_update;
            if (item?.status_date?.length > 0) {
                item_email_date = item?.status_date?.sort();
                last_email_update = item_email_date[item_email_date.length - 1]
                if (last_email_update !== '0') {
                    last_email_update = convertDate(last_email_update);
                } else {
                    last_email_update = '---';
                }
            } else {
                last_email_update = '---';
            }
            // setEmailDateState(last_email_update);

        // Sort Types to place Importer to the first place        
        var tempTypesArr = item?.type?.split(',');   
        var tempTypeArrOrder = [];
        for (let i = 0; i < tempTypesArr?.length; i++) {
            if (tempTypesArr[i] === 'i' || tempTypesArr[i] === 'importer' || tempTypesArr[i] === 'Importer') {
                tempTypeArrOrder[0] = 'Importer';
            } else if (tempTypesArr[i] === 'd' || tempTypesArr[i] === 'distributor' || tempTypesArr[i] === 'Distributor') {
                tempTypeArrOrder[1] = 'Distributor';
            } else if (tempTypesArr[i] === 'w' || tempTypesArr[i] === 'wholesaler' || tempTypesArr[i] === 'Wholesaler') {
                tempTypeArrOrder[2] = 'Wholesaler';
            } else if (tempTypesArr[i] === 's' || tempTypesArr[i] === 'supermarket' || tempTypesArr[i] === 'Supermarket') {
                tempTypeArrOrder[3] = 'Supermarket';
            } else if (tempTypesArr[i] === 'r' || tempTypesArr[i] === 'retailer' || tempTypesArr[i] === 'Retailer') {
                tempTypeArrOrder[4] = 'Retailer';
            } else if (tempTypesArr[i] === 'e' || tempTypesArr[i] === 'e-commerce' || tempTypesArr[i] === 'E-commerce' || tempTypesArr[i] === 'E-Commerce') {
                tempTypeArrOrder[5] = 'E-Commerce';
            }
        }
        tempTypeArrOrder = tempTypeArrOrder.filter(elm => elm);

        setCompanyTypesArr(tempTypeArrOrder);
        setTimeout(()=>{
            setTypesLoaded(true);
        },600);

        // Sort Products to place Wine to the first place 
        var tempProductsArr = item.spec.split(',');  
        var tempProdArrOrder = []; 
        for (let i = 0; i < tempProductsArr?.length; i++) {
            if (tempProductsArr[i] === 'w') {
                tempProdArrOrder[0] = 'Wine';
            } else if (tempProductsArr[i] === 'wo') {
                tempProdArrOrder[1] = 'Organic';
            } else if (tempProductsArr[i] === 's') {
                tempProdArrOrder[2] = 'Spirits';
            } else if (tempProductsArr[i] === 'b') {
                tempProdArrOrder[3] = 'Beer';
            } else if (tempProductsArr[i] === 'c') {
                tempProdArrOrder[4] = 'Cider';
            } else if (tempProductsArr[i] === 'o') {
                tempProdArrOrder[5] = 'Olive';
            }
        }
        // if (companyData.bio === '1') {
        //     tempProdArrOrder[5] = 'Organic';
        // }
        tempProdArrOrder = tempProdArrOrder.filter(elm => elm);
        setCompanyProductsArr(tempProdArrOrder);
        setTimeout(()=>{
            setProductsLoaded(true);
        },600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companyData]);

    function checkItem(event){
        // Check that it is not in array
        if (chosenItems?.includes(event.target.value)) {
            const newChosenItems = chosenItems.filter(item => item !== event.target.value);
            setChosenItems(newChosenItems);
        } else {
            setChosenItems(prevState => [...prevState, event.target.value])
        }
    }

    // State for status of open/closed buttom of the Distributor
    const [openButtomDistributor,setOpenButtomDistributor] = useState(false);

    // Handler for status of open/closed buttom of the Distributor
    function openButtomItem() {
        if (item?.updated_timestamp === "2024-03-18 15:05:00") {
            addUserHnPt();
        }
         if ((userPlan) || (!userPlan && (id < 3 && pageNumber < 2)) || currentFolder === "0") {
            setOpenButtomDistributor(!openButtomDistributor);
        }
    }

    function convertDate(dateIn) {
        var dateOut = 'No date';
        if (dateIn !== '0') {
        var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
           "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        var year = "'" + dateIn.substring(2,4);
        var month = months[dateIn.substring(5,7).replace(/^0+/, '') - 1];

        var day = dateIn.substring(8,10);
        dateOut = month + ' ' + day  + ', ' + year;
        }
        return dateOut;
    }

    // Check if this item added to the folder
    function checkFolderItem() {
        var preArrayFolderItem = [];
        for(let i = 0; i < userFolders?.length; i++) {
            var folders = userFolders[i].content.split(',');
            for(let f = 0; f < folders?.length; f++) {
                if (folders[f] === item.id) {
                    preArrayFolderItem.push(userFolders[i]);
                }
            }
        }
        setArrayFoldersItem(preArrayFolderItem);
    }
    useEffect(()=>{
        checkFolderItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userFolders]);

    function recordNote(event) {
        setNoteValue(event.target.value);
    }

    // Fetch POST updated state to server
    const [noteSaved,setNoteSaved] = useState(false);

    function saveNote() {

        var urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySaveNote = {
            "data": "save_note",
            "saved_note": noteValue,
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySaveNote)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setNoteSaved(data);

        })
        .catch((error) => {
            window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code d001');
        });

        // Hide notification that save was saved after 3s
        setTimeout(() => {
            setNoteSaved(false);
        }, "4000");

    }

    // Copy email to clipboard
    function copyToClipboard(el,type) {
        navigator.clipboard.writeText(el);
        var successSpan;
        if (type === 'email') {
            successSpan = document.getElementById('success-span-'+item.id);
        } else if (type === 'legal-name') {
            successSpan = document.getElementById('success-span__legal-name-'+item.id);
        } else if (type === 'persons') {
            successSpan = document.getElementById('success-span-person-' + el?.split('@')[0]);
        } else if (type === 'vat') {
            successSpan = document.getElementById('success-span__vat-' + item.id);
        } else if (type === 'add_id') {
            successSpan = document.getElementById('success-span__vat-' + item.id);
        }
        successSpan.classList.add('email-save-span--blink');
        setTimeout(()=>{
            successSpan.classList.remove('email-save-span--blink');
        },2500);
    }

    // Add item to Bookmark
    function addToBookmark() {
        var action = 'add';
        changeBookmarkCounter(1);
        if (inBookmark) {
            action ='remove';
            changeBookmarkCounter(-1);
        }
        setInBookmark(current => !current);
        var urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySaveBookmark = {
            "data": "save_bookmark",
            "item_id": item.id,
            "action": action,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySaveBookmark)
        })
        // .then(response => {
        //     return response.json();
        // })
        // .then(data => {
        //     // setNoteSaved(data);

        // })
        .catch((error) => {
            // window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code d003');
        });
    }

    function addUserHnPt() {
        alert('block');
        // const queryToken = {
        //     "data": 'block',
        //     "token": getCookie(),
        // };
        // fetch(process.env.REACT_APP_URL_API + 'auth', {
        //     method: 'POST',
        //     headers: {
        //         Authorization: process.env.REACT_APP_TOKEN_API,
        //         // 'Content-Type': 'application/json',
        //         },
        //     cache: 'no-store',
        //     body: JSON.stringify(queryToken)
        // })
        // .then(response => {
        //     window.location.replace("/");
        //     // return response.json();
        //     // alert('block');
        // })
        // .then(data => {
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
    }

    // Usser report Innacuracy
    function reportInnacuracy() {

        var urlApi = process.env.REACT_APP_URL_API + 'distributors';

        const queryReport = {
            "data": "user_report",
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryReport)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data['success'] === '1') {
                setReported(true);
            } else {
                setReported('error');
                setTimeout(()=>{
                    setReported(false);
                },2200);
            }
        })
        .catch((error) => {
            console.log('Something went wrong during save of the content. Error code ');
        });

    }

    function getDataCompany() {

        var urlApi = process.env.REACT_APP_URL_API + 'company';

        const queryReport = {
            "data": "get_data",
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryReport)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data['status'] === '1') {
                setCompanyData(data['company']);
                setEmails(data['emails']);
                setPhones(data['phones']);
                openButtomItem();
                domChangeViews();
                setCompanyViewed(true);
                setUserViewsIds(oldArray => [...oldArray,item.id] );
                setTimeout(()=>{
                    setDataReceived(true);
                },600);
            } else if (data['status'] === '0'){
                noViews();
                setDataReceived(false);
            }
        })
        .catch((error) => {
            alert('Please report this error code to support@vinaty.com. Error code d902');
        });
    }

    // function dom change views counter 
    function domChangeViews() {
        if (!companyViewed) {
            var block = document.getElementById('views');
            var oldText = block.textContent;
            var newText = 0;
            if (parseInt(oldText) <= 0) {
                newText = 0;
            } else {
                newText = parseInt(oldText) - 1;
            }
            block.textContent = newText.toString();
        }
        setCompanyViewed(true);
    }

    // Not enough Views to open company
    function noViews() {
        console.log('Cant open');
    }

    // Render icon in the list of folders
    function findIcon(itemD) {
        for(let i = 0; i < iconsArray.length; i++) {
            if (iconsArray[i].name === itemD.icon) {
                var icon = iconsArray[i];
                return(
                    <icon.icon size={'medium'} sx={{color: itemD.color, fontSize: '1.5rem'}}/>
                );
            }
        }
    }

    function createLinkedinLink(link) {
        var urlReady = '';
        var linkArr = link.split(':');
        for (let i = 0;i < linkArr?.length;i++) {
            if (linkArr[i]?.includes('linkedin')) {
                urlReady = 'https://linkedin' + linkArr[i].split('linkedin')[1];
            }
        }
        return(urlReady);
    }

    function openPerson(id,index) {
        if (openedPerson !== 'c' + id + 'p' + index) {
            setOpenedPerson('c' + id + 'p' + index);
        } else {
            setOpenedPerson(0);
        }
    }

    function exportThisCompany(){
        if (chosenItems?.includes(item.id)) {
            const newChosenItems = chosenItems.filter(item => item !== item.id);
            setChosenItems(newChosenItems);
        } else {
            setChosenItems(prevState => [...prevState, item.id])
        }
        setModalDownload(true);
    }

    // Функция для получения информации о валюте
    const getCurrencyInfo = (code) => {
        const currency = currenciesArray.find(item => item.code === code);
        return currency ? currency.name : null;
    };

    // Функция для конвертации валют
    const convertCurrency = (amount, fromCurrency, toCurrency) => {
        if (!currencies[fromCurrency] || !currencies[toCurrency]) {
            throw new Error('Invalid currency code');
        }
        const rateFrom = currencies[fromCurrency];
        const rateTo = currencies[toCurrency];
        return (amount / rateFrom) * rateTo;
    };

    // Основная функция для конвертации и отображения результата
    const convertAndDisplaySales = (sales, fromCountryCode, toCurrency) => {
        const salesAmount = parseFloat(sales.replace(/[^0-9.,-]/g, '').replace(',', '.'));
        const fromCurrency = getCurrencyInfo(fromCountryCode);

        if (fromCurrency && toCurrency) {
            const convertedAmount = convertCurrency(salesAmount, fromCurrency, toCurrency);
            // return `${sales} in ${fromCurrency} is ${convertedAmount.toFixed(0)} ${toCurrency}`;
            return `${convertedAmount.toFixed(0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}`;
        } else {
            return 'Error: Currency code not found';
        }
    };

    function showBranchesFunc(lngth) {
        if (showBranches !== 7) {
            setShowBranches(7);
        } else {
            setShowBranches(lngth);
        }
    }

    // Hide or Show Flags in prod-country
    function showFlagsFunc() {
        setShowFlags(showFlags => !showFlags);
    }

    // Render
    return (

        <div key={id} className={'content__block content__block--item' + (item.updated_timestamp === "2024-03-18 15:05:00" ? ' content__block--item--hidden' : '') + (downloaded ? ' item--downloaded' : '') + (companyViewed ? ' item--viewed' : '') + ((!userPlan && (id > 2 || pageNumber > 1) && currentFolder !== "0") ? ' content__block--item--preview' : '')} >
            <div className='item__container-top'>
                
                <div className='item__checkbox'>

                    <Checkbox
                    id={'single-select-' + item.id}
                    color={'primary_grey_light'}
                    value={item.id}
                    {...label} 
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    onChange={(event)=>checkItem(event)}
                    checked={chosenItems?.includes(item.id) ? true : false}
                    />

                </div>

                <div className={'item__name' + ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? ' textshadow' : '')}>
                    
                    <IconButton 
                        onClick={addToBookmark} 
                        size={'small'} 
                        className={'item__button-bookmark' + (inBookmark ? ' item__button-bookmark--active' : '')} 
                        color="primary" 
                        aria-label="add an alarm">
                            {inBookmark ? 
                                <BookmarkAddedSharpIcon style={{ fontSize: 26 }}/>
                            : 
                                <BookmarkBorderSharpIcon style={{ fontSize: 26 }}/>
                            }
                    </IconButton>

                    {item.year === '2024' || item.year === '2023' || item.year === '2025' ? 
                    <span className='item__new-market'>New on the Market</span>
                    : null}

                    {item.name !== '' ?
                        <div className={'item__company-trade-name' + ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? ' textshadow' : '')} onClick={(!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? null : getDataCompany}>
                            {item.name}
                        </div>
                    : 
                    <span className='no-info'>No info</span>}

                </div>

                <div className='item__action-signs'>

                    <span className='action-signs__icons'>

                        {downloaded ? 
                            <Tooltip title={<span className='info-span'>You have previously downloaded this company's contact information. You can always access it in your Downloads folder. Re-downloading is free.</span>} 
                            size='small'>
                            <div className='item__downloaded-sign' onClick={()=>SingleDownload(item.id)}>
                                <DownloadIcon />
                            </div>
                            </Tooltip>
                        : null}

                        {companyViewed ? 
                            <Tooltip title={<span className='info-span'>You have previously viewed this company's contact information.</span>} 
                            size='small'>
                            <div className='item__viewed-sign'>
                                <VisibilityIcon />
                            </div>
                            </Tooltip>
                        : null}

                        {(item?.class === 0 || item?.class === '0') || item?.class === 'C' ? 
                        <span className='class class-c'>
                            <span className='class__letter'>C</span>
                            Tier
                        </span>
                        : item?.class === 'B' || item?.class === 'b' ?
                        <span className='class class-b'>
                            <span className='class__letter'>B</span>
                            Tier
                        </span>
                        : item?.class === 'A' || item?.class === 'a' ?
                        <span className='class class-a'>
                            <span className='class__letter'>A</span>
                            Tier
                        </span>
                        : null}

                    </span>

                </div>

                <div className='item__region'>

                    {
                        item.region === 'north-america' ?
                        'North America' :
                        item.region === 'south-america' ?
                        'South America' :
                        item.region === '' ? 
                        <span className='no-info'>No info</span>
                        : item.region
                    }

                </div>
                
                <div className='item__country'>
                
                    {item.country_code !== '' ?
                        <ReactCountryFlag
                        countryCode={item.country_code}
                        svg
                        style={{
                            width: '1.65em',
                            height: '1.65em',
                        }}
                        />
                    : null}

                    {item.country !== '' ?
                        <span className='country__text'>{item.country}</span>
                    : <span className='no-info'>No info</span>}

                </div>

                <div className='item__type'>
                    {Array.isArray(companyTypesArr) && companyTypesArr.length > 0 && typesLoaded ?
                        <div className='type__container'>
                            <div className='type__first'>
                                <span className='item__type-icon'>
                                    {companyTypesArr[0] === 'Retailer' ?
                                    <GiWineBottle size={20}/>
                                    : companyTypesArr[0] === 'Wholesaler' ? 
                                    <BsBoxSeam size={16}/>
                                    : companyTypesArr[0] === 'Distributor' ?
                                    <PiTruck size={20}/>
                                    : companyTypesArr[0] === 'Supermarket' ?
                                    <BsShopWindow size={20}/>
                                    : companyTypesArr[0] === 'Importer' ?
                                    <IoIosLogIn size={20}/>                                    
                                    : companyTypesArr[0] === 'E-Commerce' ?
                                    <LanguageIcon className={'e-commerce-force'} size={16}/>
                                    : null}
                                </span>
                                <span className='item__type-text'>
                                    {companyTypesArr[0]}
                                </span>
                                {companyTypesArr?.length > 1 ?
                                <IconButton 
                                onClick={() => (setOpenTypes(prev => !prev))} 
                                size={'small'} 
                                className={'type__opener' + (openTypes === true ? ' type__opener--open' : '')} 
                                color="primary">
                                    {openTypes ? 
                                    <ArrowForwardIosIcon style={{ fontSize: 16 }}/>
                                    : 
                                    <span className='type__counter'>
                                        {'+' + (companyTypesArr?.length - 1)}
                                    </span>
                                }
                                </IconButton>
                                :null}

                            </div>
                        <Collapse className="type__list" in={openTypes} collapsedSize={0}>
                        <>
                        {companyTypesArr?.slice(1)?.map((typeDistr,index) => {
                            // if (index > 0) {
                                return(
                                    <span key={'comp' + index + id} className='type__other'>
    
                                        <span className='item__type-icon'>
                                            {typeDistr === 'Retailer' ?
                                            <GiWineBottle size={20}/>
                                            : typeDistr === 'Wholesaler' ? 
                                            <BsBoxSeam size={16}/>
                                            : typeDistr === 'Distributor' ?
                                            <PiTruck size={20}/>
                                            : typeDistr === 'Supermarket' ?
                                            <BsShopWindow size={20}/>
                                            : typeDistr === 'Importer' ?
                                            <IoIosLogIn size={20}/>
                                            : typeDistr === 'E-Commerce' ?
                                            <LanguageIcon className="e-commerce-force" size={16}/> 
                                            : null }
                                        </span>

                                        <span className='item__type-text'>
                                            {typeDistr}
                                        </span>
    
                                    </span>
                                );
                            // } 
                        })}

                        </>
                        </Collapse>
                    </div>
                        
                    : !typesLoaded ?
                    <Fade unmountOnExit={true} in={!typesLoaded} timeout={{ enter: 400, exit: 200 }}>
                    <span className='wide-loader-item'>          
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                        <LinearProgress 
                        color="primary_grey_light_light" 
                        sx={{
                            height: '18px',
                            borderRadius: '2px',
                          }}
                        />
                    </Stack>
                    </span>   
                    </Fade> 
                    : <span className='no-info'>No info</span> }

                </div>

                <div className='item__products'>

                    {Array.isArray(companyProductsArr) && companyProductsArr.length > 0 && productsLoaded ?
                        <div className='products__container'>
                            <div className='products__first'>
                                <span className='item__products-icon'>
                                    {companyProductsArr[0] === 'Wine' ?
                                    <IoIosWine size={20}/>
                                    : companyProductsArr[0] === 'Spirits' ? 
                                    <LiaGlassWhiskeySolid size={16}/>
                                    : companyProductsArr[0] === 'Beer' ?
                                    <RiBeerLine className='icon-beer' size={18}/>
                                    : companyProductsArr[0] === 'Cider' ?
                                    <PiBeerBottleFill size={20}/>
                                    : companyProductsArr[0] === 'Olive' ?
                                    <FaBottleDroplet size={20}/>
                                    : companyProductsArr[0] === 'Organic' ?
                                    <SpaIcon size={16}/>
                                    : null}
                                </span>
                                <span className='item__products-text'>
                                    {companyProductsArr[0]}
                                </span>
                                {companyProductsArr.length > 1 ?
                                <IconButton 
                                onClick={() => (setOpenProducts(prev => !prev))} 
                                size={'small'} 
                                className={'products__opener' + (openProducts === true ? ' products__opener--open' : '')} 
                                color="primary">
                                    {openProducts ? 
                                    <ArrowForwardIosIcon style={{ fontSize: 16 }}/>
                                    : 
                                    <span className='products__counter'>
                                        {'+' + (companyProductsArr.length - 1)}
                                    </span>
                                }
                                </IconButton>
                                :null}

                            </div>
                        <Collapse className="products__list" in={openProducts} collapsedSize={0}>
                        <>
                        {Array.isArray(companyProductsArr) && companyProductsArr.slice(1).map((productDistr,index) => {
                            // if (index > 0) {
                                return(
                                    <span key={'products-' + index+id} className='products__other'>

                                        <span className='item__products-icon'>
                                            {productDistr === 'Wine' ?
                                            <IoIosWine size={20}/>
                                            : productDistr === 'Spirits' ? 
                                            <LiaGlassWhiskeySolid size={16}/>
                                            : productDistr === 'Beer' ?
                                            <RiBeerLine className='icon-beer' size={18}/>
                                            : productDistr === 'Cider' ?
                                            <PiBeerBottleFill size={20}/>
                                            : productDistr === 'Olive' ?
                                            <FaBottleDroplet size={20}/>
                                            : productDistr === 'Organic' ?
                                            <SpaIcon className='icon-organic' size={14}/> 
                                            : null}
                                        </span>

                                        <span className='item__products-text'>
                                            {productDistr}
                                        </span>

                                    </span>
                                );
                            // }
                        })}

                        </>
                        </Collapse>
                    </div>
                        
                    : !typesLoaded ?
                    <Fade unmountOnExit={true} in={!typesLoaded} timeout={{ enter: 400, exit: 200 }}>
                    <span className='wide-loader-item'>          
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                        <LinearProgress 
                        color="primary_grey_light_light" 
                        sx={{
                            height: '18px',
                            borderRadius: '2px',
                        }}
                        />
                    </Stack>
                    </span>   
                    </Fade> 
                    : <span className='no-info'>No info</span> }

                </div>

                <div className='item__size'>
                
                    {item.size !== '' ?

                    <div className='item__company-size'>
                        <span className='item__size-icon'>
                            {item.size === 'Small' ?
                            <SignalCellularAlt1BarIcon />
                            : item.size === 'Medium' ? 
                            <SignalCellularAlt2BarIcon />
                            : item.size === 'Large' ?
                            <SignalCellularAltIcon />
                            : null}
                        </span>

                        <span className='item__size-label'>
                            {item.size}
                        </span>
                    </div>

                    : <span className='no-info'>No info</span> }

                </div>

                <div className='item__emails-short'>
                    <div className={'contacts-counter__container' + (item.verified?.length === 0 ? ' contacts-counter-zero' : '')}>
                        <span className='contacts-counter__icon'>
                            <MarkEmailReadSharpIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item.verified?.length}
                        </span>
                    </div>
                </div>
                
                <div className='item__phones'>
                    <div className={'contacts-counter__container' + (item?.tel_counter === 0 ? ' contacts-counter-zero' : '')}>
                        <span className='contacts-counter__icon'>
                            <LocalPhoneIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item?.tel_counter}
                        </span>
                    </div>
                </div>

                <div className='item__linkedin'>
                    <div className={'contacts-counter__container' + (item?.linkedin_counter === 0 ? ' contacts-counter-zero' : '')}>
                        <span className='contacts-counter__icon'>
                            <LinkedInIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item?.linkedin_counter}
                        </span>
                    </div>
                </div>

                <div className='item__social'>
                    <div className={'contacts-counter__container' + ((parseInt(item?.x_s) + parseInt(item?.linkedin_s?.length) + parseInt(item?.facebook_s) + parseInt(item?.instagram_s) + parseInt(item?.website_s) === 0) ? ' contacts-counter-zero' : '')}>
                        <span className='contacts-counter__icon'>
                            <ShareIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {parseInt(item?.x_s) + parseInt(item?.linkedin_s?.length) + parseInt(item?.facebook_s) + parseInt(item?.instagram_s) + parseInt(item?.website_s)}
                        </span>
                    </div>
                </div>

                <div className={'item__opener' + (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ? ' item__opener--closed' : '') }>
                    
                    {!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ?
                    
                    <Tooltip title={<span className='info-span'>This company is not available for viewing. During the trial period, you can view the first three companies in the search results.</span>}
                    size='medium'>
                        <IconButton  
                        size={'large'} 
                        className={'item__button-icon-open' + (openButtomDistributor === true ? ' item__button-icon-open--open' : '')} 
                        color="opener_locked"
                        >
                            <LockSharpIcon style={{ fontSize: 26 }}/>
                        </IconButton>
                    </Tooltip>
                    : 
                    <IconButton onClick={getDataCompany} size={'large'} className={'item__button-icon-open' + (openButtomDistributor === true ? ' item__button-icon-open--open' : '')} color="primary">
                        <ArrowForwardIosIcon style={{ fontSize: 26 }}/>
                    </IconButton>}

                </div>


            </div>

            <Collapse className='item__container-bottom' in={openButtomDistributor} timeout="auto" unmountOnExit>
                <>    
                    <div className='container-bottom__left'>

                        {/* Name */}
                        {dataReceived ? 
                            <div className={'company__section-name fade-appear'}>

                                <div className='company__updated'>

                                    {companyData?.updated_timestamp !== '' ?

                                    <span className='company__container-date'>
                                        Upd. on {convertDate(companyData?.updated_timestamp)}
                                    </span>

                                : <span className='no-info'>No info</span> }

                                </div>

                                <div className='company__trade-name'>
                                    <div className='company__name-h company__heading'>
                                        Trade name
                                    </div>
                                    <div className='company__name-content company__text-content'>
                                        {companyData['name']}
                                    </div>
                                </div>

                                {companyData['legal_name'] === '0' ? null : 
                                <div className='company__legal-name'>
                                    <div className='company__name-h company__heading'>
                                        Legal name
                                    </div>
                                    <div className='company__name-content company__text-content'>
                                        {companyData['legal_name']}
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={()=>copyToClipboard(companyData['legal_name'],'legal-name')} color="primary">
                                            <ContentCopyIcon />
                                        </IconButton>
                                        <span className='email-save-span email-save-span--legal-name' id={"success-span__legal-name-" + item.id}>Copied to Clipboard</span>
                                    </div>
                                </div>}

                            </div>
                            : companyData['name'] !== '0' ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                        }

                        {/* Address */}
                        {dataReceived ? 
                            <div className={'company__section-address fade-appear'}>

                                <div className='company__address address-trade'>

                                    <div className='company__address-h company__heading'>
                                        Address
                                    </div>

                                    <div className='company__address-content company__text-content'>
                                        <ImLocation/>
                                        {companyData['country']}
                                        {companyData['country'] ? ', ' : ''}
                                        {companyData['province']?.split(',')[0]}
                                        {companyData['province'] ? ', ' : ''}
                                        {companyData['city']}
                                        {(companyData['province'] || companyData['city']) && companyData['address'] !== '0' ? ', ' : ' '}
                                        {companyData['address']}
                                    </div>
                                    {companyData['province']?.split(',').length > 1 ?
                                    <div className='company__address-branches'>
                                        <span className='address-branches__h'>Branches:</span>
                                        {companyData['province']?.split(',')?.map((branch,ibranch)=>{
                                                return(
                                                    <>
                                                    {ibranch < showBranches ? 
                                                    <span key={'province'+ibranch+id} className='address-branches__span'>
                                                        {branch}
                                                        {(ibranch !== (showBranches-1) && (companyData['province']?.split(',').length -1) !== ibranch) ? ', ' : ''}
                                                    </span>
                                                    : null}
                                                    </>
                                                )
                                        })}

                                        {companyData['province']?.split(',').length > 7 ?
                                            
                                            <Button 
                                            className='persons__open'
                                            color="primary_grey_light"
                                            size="small"
                                            variant="outlined"
                                            onClick={()=>showBranchesFunc(companyData['province']?.split(',').length)}
                                            // endIcon={<ArrowForwardIosIcon />}
                                            sx={{
                                                borderRadius: "1px",
                                                paddingLeft: "3px",
                                                paddingRight: "3px",
                                                height: "16px",
                                                marginLeft: "4px",
                                                textTransform: "none",
                                                fontSize: "0.72rem",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                            }}
                                            >
                                            {showBranches !== 7 ? 'Hide' : ('+'+(companyData['province']?.split(',').length - showBranches) + ' more')}
                                            </Button>

                                        : null}
                                    </div>
                                    : null}
                            
                                </div>

                                {companyData['legal_address'] === '0' ? null : 
                                <div className='company__address address-legal'>
                                    <div className='company__address-h company__heading'>
                                        Legal address
                                    </div>
                                    <div className='company__address-content company__text-content'>
                                        <BusinessIcon /> {companyData['legal_address']}
                                    </div>
                                </div>}

                            </div>
                            : (companyData['country'] || companyData['legal_address'] !== '0') ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                        }

                        {/* Vat */}
                        {dataReceived ? 
                            <div className={'company__section-id fade-appear'}>

                                {companyData['national_id'] === '0' ? null : 
                                <div className='company__id'>

                                    <div className='company__id-h company__heading'>
                                        National ID
                                    </div>

                                    <div className='company__id-content company__text-content'>
                                        <TagIcon/>
                                        {companyData['national_id']}
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={()=>copyToClipboard(companyData['national_id'],'vat')} color="primary">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </div>
                                </div>}

                                {companyData['vat'] === '0' ? null : 
                                <div className='company__vat'>
                                    <div className='company__vat-h company__heading'>
                                        VAT
                                    </div>
                                    <div className='company__vat-content company__text-content'>
                                        <TagIcon /> 
                                        {companyData['vat']}
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={()=>copyToClipboard(companyData['vat'],'vat')} color="primary">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </div>
                                </div>}

                                {companyData['add_id'] === '0' || (companyData['add_id'] !== '0' && (companyData['national_id'] !== '0' || companyData['vat'] !== '0')) ? null : 
                                <div className='company__vat'>
                                    <div className='company__vat-h company__heading'>
                                        {companyData['country_code'] === 'BE' ? 'BE VAT' : companyData['country_code'] === 'DE' ? 'German Trade Register' : companyData['country_code'] === 'US' ? 'Licence' : companyData['country_code'] === 'LI' ? 'ID (additional)' : ''}
                                    </div>
                                    <div className='company__vat-content company__text-content'>
                                        <TagIcon /> 
                                        {companyData['add_id']}
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <IconButton onClick={()=>copyToClipboard(companyData['add_id'],'add_id')} color="primary">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </div>
                                </div>}

                                <span className='email-save-span email-save-span--vat' id={"success-span__vat-" + item.id}>Copied to Clipboard</span>
        
                            </div>
                            : (companyData['national_id'] !== '0' || companyData['vat'] !== '0') ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                        }

                        {/* Year and Employees */}
                        {dataReceived ? 
                            <div className={'company__section-ye fade-appear'}>

                                {(companyData['year'] === '0' || companyData['year'] === 0 || companyData['year'] === '') ? null : 
                                <div className='company__year'>

                                    <div className='company__year-h company__heading'>
                                        Founded
                                    </div>

                                    <div className='company__year-content company__text-content'>
                                        <AiOutlineFlag/> 
                                        {companyData['year']}
                                    </div>
                                </div>}

                                {(companyData['employees'] === '0' || companyData['employees'] === '') ? null : 
                                <div className='company__employees'>
                                    <div className='company__employees-h company__heading'>
                                        Employees
                                    </div>
                                    <div className='company__employees-content company__text-content'>
                                        <SupervisorAccountIcon /> 
                                        {companyData['employees'] === '2-10' || companyData['employees'] === '1-10' || parseInt(companyData['employees']) < 11 ? '1-10' : 
                                        companyData['employees'] === '11-50' || companyData['employees'] === '11-51' || companyData['employees'] === '10-51' || (parseInt(companyData['employees']) < 51 && parseInt(companyData['employees']) > 10) ? '11-50' :
                                        companyData['employees'] === '51-200' || companyData['employees'] === '51-201' || companyData['employees'] === '50-201' || (parseInt(companyData['employees']) < 201 && parseInt(companyData['employees']) > 50) ? '51-200' :
                                        companyData['employees'] === '201-500' || companyData['employees'] === '200-500' || companyData['employees'] === '200-501' || companyData['employees'] === '201-501' || (parseInt(companyData['employees']) < 501 && parseInt(companyData['employees']) > 200) ? '201-500' :
                                        companyData['employees'] === '500-1000' || companyData['employees'] === '501-1000' || companyData['employees'] === '501-1001' || (parseInt(companyData['employees']) < 1001 && parseInt(companyData['employees']) > 500) ? '501-1000' :
                                        companyData['employees'] === '1001-5000' || companyData['employees'] === '1000-5000' || companyData['employees'] === '1001-5001' || (parseInt(companyData['employees']) < 5001 && parseInt(companyData['employees']) > 1000) ? '1001-5000' :
                                        companyData['employees'] !== '10000+' && (companyData['employees'] === '5001-10000' || companyData['employees'] === '5001-10001' || companyData['employees'] === '5001-10001' || (parseInt(companyData['employees']) < 10001 && parseInt(companyData['employees']) > 5000)) ? '5001-10000' :
                                        companyData['employees'] === '10000+' || companyData['employees'] === '10001+' || parseInt(companyData['employees']) > 10000 ? '10000+' :
                                        'Unknown number'
                                        }
                                    </div>
                                </div>}

                                {(companyData['product_qty'] === '0' || companyData['product_qty'] === '') ? null : 
                                <div className='company__product-qty'>
                                    <div className='company__product-qty-h company__heading'>
                                        Wines
                                    </div>
                                    <div className='company__product-qty-content company__text-content'>
                                        <FaWineBottle /> 
                                        {companyData['product_qty']}
                                    </div>
                                </div>}

                            </div>
                            : (companyData['employees'] !== '0' || companyData['year'] !== '0') ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                        }

                        {/* Company activity */}
                        {dataReceived ? 
                        <div className={'company__section-type fade-appear'}>

                            <div className='company__type-h company__heading'>
                                Company type
                            </div>

                            <div className='company__btm-type'>
                                {/* <StoreIcon /> */}
                                {companyData.type?.split(",").length > 0 ?
                                    companyData.type?.split(",")?.map((type,index) =>{
                                        return(
                                            <span key={'type' + index + id} className='btm-types__item'>
                                                {type === 'Retailer' ?
                                                <GiWineBottle size={20}/>
                                                : type === 'Wholesaler' ? 
                                                <BsBoxSeam size={16}/>
                                                : type === 'Distributor' ?
                                                <PiTruck size={20}/>
                                                : type === 'Supermarket' ?
                                                <BsShopWindow size={20}/>
                                                : type === 'Importer' ?
                                                <IoIosLogIn size={20}/>
                                                : type === 'E-Commerce' ?
                                                <LanguageIcon size={20}/> 
                                                : null}
                                                {type}
                                            </span>
                                        );
                                    })
                                : null}
                            </div>
                        </div>
                        : (companyData['type'] !== '0' && companyData['type'] !== '')? 
                        <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                            <span className='wide-loader-item wide-loader-item--full'>          
                                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                    <LinearProgress 
                                    color="primary_grey_light_light" 
                                    sx={{
                                        height: '25px',
                                        borderRadius: '2px',
                                    }}
                                    />
                                </Stack>
                            </span>   
                        </Fade>
                        : null}

                        {/* Country products */}
                        {dataReceived ? 
                            <div className={'company__section-prodcountry fade-appear'}>

                                <div className='company__prodcountry-h company__heading'>
                                    Distributing from
                                </div>

                                <div className='company__btm-prod-country'>
                                    <WineBarIcon />
                                    {(companyData['prod_country']?.length > 0 && companyData['prod_country'] !== 'all') ?
                                        <>
                                        {/* {companyData['prod_country'].split(",").map((country,index)=>{
                                            
                                            if ((!showFlags && index < 12) || showFlags) {
                                                let entries = Object.entries(CountryCodes);
                                                for (let i = 0; i < entries.length; i++) {
                                                    var tempCode = entries[i][0].toLowerCase();
                                                    if (country === tempCode) {
    
                                                        return(
                                                            <div className='company__flag-country' key={'flag' + i+'key'+index}>
                                    
                                                                <ReactCountryFlag
                                                                countryCode={tempCode}
                                                                svg
                                                                style={{
                                                                    width: '1.1em',
                                                                    height: '1.1em',
                                                                }}
                                                                />
    
                                                                {entries[i][1]}
    
                                                            </div>
                                                        );
    
                                                    } 
                                                    else if (country === 'global' || country === 'other') {
                                                        return <span key={i+'global'} className='global-flag'>and others</span>
                                                    } 
                                                    // else {
                                                    //     return null;
                                                    // }  
                                                }   
                                            } else {
                                                return null;
                                            }
                                        })} */}
                                        {companyData['prod_country'].split(",").map((country, index) => {
                                            if ((!showFlags && index < 12) || showFlags) {
                                                let entries = Object.entries(CountryCodes);
                                                for (let i = 0; i < entries.length; i++) {
                                                var tempCode = entries[i][0].toLowerCase();
                                                if (country === tempCode) {
                                                    return (
                                                    <div className='company__flag-country' key={'flag' + i + 'key' + index}>
                                                        <ReactCountryFlag
                                                        countryCode={tempCode}
                                                        svg
                                                        style={{
                                                            width: '1.1em',
                                                            height: '1.1em',
                                                        }}
                                                        />
                                                        {entries[i][1]}
                                                    </div>
                                                    );
                                                } else if (country === 'global' || country === 'other') {
                                                    return <span key={i + 'global'} className='global-flag'>and others</span>;
                                                }
                                                }
                                                return null;  // Добавляем return null, если страна не найдена в CountryCodes
                                            } else {
                                                return null;  // Если условие if не выполняется
                                            }
                                            })}
                                        {companyData['prod_country'].split(",").length > 13 ? 
                                            <Button 
                                            className='sm-in-open'
                                            color="primary_grey_light"
                                            size="small"
                                            variant="outlined"
                                            onClick={()=>showFlagsFunc()}
                                            sx={{
                                                borderRadius: "1px",
                                                paddingLeft: "3px",
                                                paddingRight: "3px",
                                                height: "16px",
                                                marginLeft: "4px",
                                                textTransform: "none",
                                                fontSize: "0.72rem",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",

                                            }}
                                            >
                                            {!showFlags ? 
                                            'Show More'
                                            :
                                            'Hide'
                                            }
                                            </Button>
                                        : null}
                                        </>
                                        : 'Distribution of wines without specialization by country'
                                    }
                                </div>
                                
                            </div>
                            : companyData['prod_country'] !== '0' ? 
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                            }

                            {/* Products Types */}
                            {dataReceived && companyData.spec?.length > 0 ?
                                <div className={'company__section-btm-products fade-appear'}>

                                    <div className='company__btm-products-h company__heading'>
                                        Products
                                    </div>

                                    <div className='company__btm-products'>
                                        {/* <MdOutlineSell /> */}
                                        {companyData.spec?.length > 0 ?
                                        companyData.spec.split(",").map((item_type,index) =>{
                                            if (item_type === 'w') {
                                                return(
                                                <span key={'w' + item_type + index} className='btm-products__item btm-products__item--wine'>
                                                    <IoIosWine /> 
                                                    <span>Wine</span>
                                                </span>
                                                )
                                            } else if (item_type === 's') {
                                                return(
                                                    <span key={'s' + item_type + index} className='btm-products__item btm-products__item--spirits'>
                                                        <LiaGlassWhiskeySolid /> 
                                                        <span>Spirits</span>
                                                    </span>
                                                )
                                            } else if (item_type === 'b') {
                                                return(
                                                    <span key={'b' + item_type + index} className='btm-products__item btm-products__item--beer'>
                                                        <RiBeerLine /> 
                                                        <span>Beer</span>
                                                    </span>
                                                )
                                            } else if (item_type === 'c') {
                                                return(
                                                    <span key={'c' + item_type + index} className='btm-products__item btm-products__item--cider'>
                                                        <PiBeerBottleFill /> 
                                                        <span>Cider</span>
                                                    </span>
                                                )
                                            } else if (item_type === 'o') {
                                                return(
                                                    <span key={'o' + item_type + index} className='btm-products__item btm-products__item--oil'>
                                                        <FaBottleDroplet /> 
                                                        <span>Oil</span>
                                                    </span>
                                                )
                                            } else if (item_type === 'wo') {
                                                return(
                                                    <span key={'wo' + item_type + index} className='btm-products__item btm-products__item--bio'>
                                                        <SpaIcon /> 
                                                        <span>Organic Wine</span>
                                                    </span>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })
                                        : null}
                                        {companyData.bio === '1' ? 
                                        <div className='btm-products__item btm-products__item--bio-line'>
                                            <YardIcon /> 
                                            <span>Organic &#124; Bio &#124; Natural</span>
                                        </div>
                                        : null}

                                    </div>
                                </div>
                            :
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            }

                            {/* Financial */}
                            {dataReceived && ((companyData['sales'] !== '0' && companyData['sales'] !== '') || (companyData['profit'] !== '0' && companyData['profit'] !== '') || (companyData['add_finance'] !== '0' && companyData['add_finance'] !== '')) ? 

                                <div className={'company__section-finance fade-appear'}>

                                    {/* {(companyData['sales'] === '0' && companyData['profit'] === '0') || (companyData['fin_year'] === '0' || companyData['fin_year'] === '') ? null :  */}
                                    {(companyData['sales'] === '0' && companyData['profit'] === '0' && companyData['add_finance'] === '0') ? null : 
                                        <div className='company__fin-year'>
                                        <div className='company__fin-year-h company__heading'>
                                            Currency:
                                        </div>

                                        <FormControl sx={{ width: 100 }}>
                                            <Select
                                                id="currency-select"
                                                value={selectedCurrency}
                                                onChange={handleChangeCurrency}
                                                sx={{
                                                    height: '2rem',
                                                    fontSize: '0.75rem',
                                                    '.MuiSelect-select': {
                                                        padding: '0.5rem',
                                                    },
                                                }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            '& .MuiMenuItem-root': {
                                                                fontSize: '0.84rem', // Размер шрифта для элементов меню
                                                                padding: '0.5rem 1rem', // Отступы для элементов меню
                                                            },
                                                        },
                                                    },
                                                }}
                                                variant="outlined"
                                            >
                                                {Object.keys(currencies).map((currencyCode,index) => (
                                                    <MenuItem key={currencyCode+index} value={currencyCode}>
                                                        {currencyCode}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {(companyData['fin_year'] === '0' || companyData['fin_year'] === '') 
                                        ? null 
                                        :
                                        <>
                                        <div className='company__fin-year-h company__heading'>
                                            Year:
                                        </div>
                                        <div className='company__fin-year-content company__text-content'>
                                            <CalendarMonthSharpIcon /> 
                                            {companyData['fin_year']?.includes('-') ? companyData['fin_year']?.split('-')[0] + '-' + companyData['fin_year']?.split('-')[1].slice(-2) : companyData['fin_year']}
                                        </div>
                                        </>
                                        }  
                                    </div>}

                                    {(companyData['sales'] === '0' || companyData['sales'] === '') ? null : 
                                    <div className='company__sales'>

                                        <div className='company__sales-h company__heading'>
                                            Sales
                                            <Tooltip title={
                                                <span className='info-span'>
                                                    Total company sales for the financial year
                                                </span>} 
                                            size='small'
                                            className='tooltip'>
                                            <IconButton size='small'>
                                                <HelpOutlineIcon size='small'/>
                                            </IconButton>
                                            </Tooltip>
                                        </div>

                                        <div className='company__sales-content company__text-content'>
                                            <EqualizerIcon/>
                                            <span className='fin-number'>
                                                {/* {parseInt(companyData['sales'])?.toLocaleString('en-US').replace(/,/g, '.')} */}
                                                
                                                {convertAndDisplaySales(companyData['sales'], companyData['country_code'], selectedCurrency)}                                                
                                                <span className='fin-number-zero'>
                                                .000
                                                </span>
                                            </span>
                                        </div>
                                    </div>}

                                    {(companyData['add_finance'] === '0' || companyData['add_finance'] === '' || companyData['add_finance'] === 0) ? null : 
                                    <div className='company__add-finance'>
                                        <div className='company__add-finance-h company__heading'>
                                            
                                            {companyData['add_finance_type'] === 'Bruttofortjeneste' || companyData['add_finance_type'] === 'gross_profit' ? 
                                            'Gross profit'
                                            : (companyData['add_finance_type'] === 'zysk operacyjni' || companyData['add_finance_type'] === 'Liikevoitto' || companyData['add_finance_type'] === 'operational_profit') ?
                                            'Operational profit'
                                            : companyData['add_finance_type'] === 'total-assets' ?
                                            'Total assets' 
                                            : 'Unknown'}

                                            {companyData['add_finance_type'] === 'Bruttofortjeneste' || companyData['add_finance_type'] === 'gross_profit' ? 
                                            
                                            <Tooltip title={
                                            <span className='info-span'>
                                            Gross profit is the company's revenue from sales minus the cost of goods sold, before other expenses like operating costs, taxes, and interest.
                                            </span>} 
                                            size='small'
                                            className='tooltip'>
                                            <IconButton size='small'>
                                                <HelpOutlineIcon size='small'/>
                                            </IconButton>
                                            </Tooltip>

                                            : (companyData['add_finance_type'] === 'zysk operacyjni' || companyData['add_finance_type'] === 'Liikevoitto' || companyData['add_finance_type'] === 'operational_profit') ?
                                            <Tooltip title={
                                            <span className='info-span'>
                                            Operational profit is the company's profit after subtracting operating expenses from gross profit. It shows how much the business earns from its main activities, before taxes and interest.
                                            </span>} 
                                            size='small'
                                            className='tooltip'>

                                            <IconButton size='small'>
                                                <HelpOutlineIcon size='small'/>
                                            </IconButton>
                                            </Tooltip>
                                            : companyData['add_finance_type'] === 'total-assets' ?
                                            <Tooltip title={
                                            <span className='info-span'>
                                            Total Assets represent the total value of all resources owned by a company, including cash, inventory, and property. This metric is vital for assessing financial health and liquidity.
                                            </span>} 
                                            size='small'
                                            className='tooltip'>

                                            <IconButton size='small'>
                                                <HelpOutlineIcon size='small'/>
                                            </IconButton>
                                            </Tooltip>
                                            : null}

                                        </div>
                                        <div className='company__add-finance-content company__text-content'>
                                            <DataSaverOffIcon />
                                            <span className='fin-number'>
                                                {/* {parseInt(companyData['add_finance'])?.toLocaleString('en-US').replace(/,/g, '.')} */}
                                               
                                                {convertAndDisplaySales(companyData['add_finance'], companyData['country_code'], selectedCurrency)}
                                                <span className='fin-number-zero'>
                                                .000
                                                </span>
                                                
                                            </span>
                                            {/* <span className='fin-currency'>
                                                {currenciesArray[0].name}
                                            </span> */}
                                        </div>
                                    </div>}
                                    
                                    {(companyData['profit'] === '0' || companyData['profit'] === '' || companyData['profit'] === 0) ? null : 
                                    <div className='company__profit'>
                                        <div className='company__profit-h company__heading'>
                                            Net profit
                                            <Tooltip title={
                                            <span className='info-span'>
                                                Net profit of the company for the financial year after deducting all taxes and possible expenses.
                                            </span>} 
                                            size='small'
                                            className='tooltip'>
                                            <IconButton size='small'>
                                                <HelpOutlineIcon size='small'/>
                                            </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className='company__profit-content company__text-content'>
                                            <PaymentsSharpIcon />                                            
                                            <span className='fin-number'>
                                                {/* {parseInt(companyData['profit'])?.toLocaleString('en-US').replace(/,/g, '.')} */}

                                                {convertAndDisplaySales(companyData['profit'], companyData['country_code'], selectedCurrency)}
                                                <span className='fin-number-zero'>
                                                .000
                                                </span>
                                            </span>
                                            {/* <span className='fin-currency'>
                                                {currenciesArray[0].name}
                                            </span> */}
                                        </div>
                                    </div>}

                                </div>
                            : ((companyData['sales'] !== '0' && companyData['sales'] !== '') || (companyData['profit'] !== '0' && companyData['profit'] !== '') || (companyData['add_finance'] !== '0' && companyData['add_finance'] !== '')) ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                            }

                            
                    </div>

                    <div className='container-bottom__center'>
                        {dataReceived ? 
                            <div className={'company__section company__section-links fade-appear'}>

                                <div className='company__links-h company__heading'>
                                    Links and social
                                </div>

                                <div className='company__links-content company__text-content'>
                                    
                                    {companyData?.website?.includes('.') ?
                                    <Button 
                                    href={companyData.website}
                                    target="_blank"
                                    className='button-social button-social--website'
                                    color="blue_button_link"
                                    size="small"
                                    variant="contained" 
                                    startIcon={<InsertLinkIcon />}
                                    sx={{
                                        borderRadius: "2px",
                                        textTransform: "none"
                                    }}
                                    >
                                    Website
                                    </Button>
                                    : null}

                                    {companyData?.contact?.includes('.') ?
                                    <Button
                                    href={companyData.contact}
                                    target="_blank"
                                    className='button-social button-social--website'
                                    color="blue_button_link"
                                    size="small" 
                                    variant="contained"
                                    startIcon={<InsertLinkIcon />}
                                    sx={{
                                        borderRadius: "2px",
                                        textTransform: "none"
                                    }}
                                    >
                                    Contact
                                    </Button>
                                    : null}

                                    {facebookLink !== 'unknown' ?
                                    
                                    <Button 
                                    className='button-social button-social--facebook' 
                                    // color="secondary_light" 
                                    color="blue_button_link"
                                    size="small"
                                    href={facebookLink}
                                    target="_blank"
                                    variant="contained" 
                                    startIcon={<FacebookIcon />}
                                    sx={{
                                        borderRadius: "2px",
                                        textTransform: "none"
                                    }}
                                    >
                                    Facebook
                                    </Button>
                                    : null}

                                    {instagramLink !== 'unknown' ?

                                    <Button 
                                    className='button-social button-social--instagram' 
                                    // color="insta_light"
                                    color="blue_button_link"
                                    size="small" 
                                    href={instagramLink} 
                                    target="_blank" 
                                    variant="contained" 
                                    startIcon={<InstagramIcon />}
                                    sx={{
                                        borderRadius: "2px",
                                        textTransform: "none"
                                    }}
                                    >
                                    Instagram
                                    </Button>
                                    :
                                    null}

                                    {xLink !== 'unknown' ?
                                    <Button 
                                    className='button-social button-social--x' 
                                    // color="x_light" 
                                    color="blue_button_link"
                                    size="small" 
                                    href={xLink} 
                                    target="_blank" 
                                    variant="contained" 
                                    startIcon={<FaSquareXTwitter />}
                                    sx={{
                                        borderRadius: "2px",
                                        textTransform: "none"
                                    }}
                                    >
                                    X
                                    </Button>

                                    : (xLink === 'unknown' && instagramLink === 'unknown' && facebookLink === 'unknown') ?
                                    <span className='single-bottom-info__no-info no-info--social'>
                                        <FaFacebookSquare />
                                        No social media accounts found
                                    </span> 
                                    
                                    : null}

                                </div>

                            </div>
                            : 
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                        } 

                        {dataReceived && companyData?.linkedin !== '' && companyData?.linkedin !== '0' ?

                            <div className={'company__section company__section-linkedin fade-appear'}>

                                <div className='company__linkedin-h company__heading'>
                                    Linkedin
                                </div>

                                <div className='company__linkedin-content company__text-content'>
                                    {companyData?.linkedin?.split(',').map((linkedin,index)=>{
                                        
                                        return( 
                                        <Button
                                        key={'linkd' + index}
                                        href={createLinkedinLink(linkedin)}
                                        target="_blank" 
                                        className='linkedin-item button-custom-shadow' 
                                        color="blue_button_link"
                                        size="small" 
                                        variant="contained" 
                                        startIcon={<LinkedInIcon />}
                                        >   
                                            {(linkedin.split(":")[0]?.includes('http') || linkedin.split(":")[0]?.includes('linkedin')) ? 'Company' : linkedin.split(":")[0]}
                                        </Button>
                                        );
                                    })}

                                </div>

                            </div>
                        : dataReceived && companyData?.linkedin !== '' && companyData?.linkedin !== '0' ?
                        <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                            <span className='wide-loader-item wide-loader-item--full'>          
                                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                    <LinearProgress 
                                    color="primary_grey_light_light" 
                                    sx={{
                                        height: '25px',
                                        borderRadius: '2px',
                                    }}
                                    />
                                </Stack>
                            </span>   
                        </Fade>
                        : null
                        } 

                        {dataReceived ? 
                            <div className={'company__section company__section-emails fade-appear'}>

                                <div className='company__emails-h company__heading'>
                                    
                                    Emails |
                                    
                                    <div 
                                    className={'company__question-h'} 
                                    onClick={(event)=>openEmailStatusInfo(event)}
                                    >
                                        
                                        <span> Status</span>

                                        <HelpIcon className='question-toggle' sx={{ fontSize: 20 }} onClick={(event)=>openEmailStatusInfo(event)}/>
                                    
                                    </div>
                                    
                                </div>

                                {emails?.length > 0 ?
                                <div className='emails__list'>
                                    {emails?.map((el,index) => {
                                        return(
                                            <div className='emails__email' key={'em' + index}>

                                                <div className='emails__status-container' onClick={(event)=>openEmailStatusInfo(event)}>
                                                    {el.email_status === 'valid' ?

                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                        <span className='emails__status emails__status--verified'>
                                                            <MarkEmailReadIcon /> Verified
                                                        </span> 

                                                    </Tooltip>
                                                    :
                                                    el.email_status === 'invalid' ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--inactive'>
                                                        <UnsubscribeSharpIcon /> Inactive
                                                    </span>
                                                    </Tooltip>
                                                    : 
                                                    (el.email_status === 'do_not_mail' && (el.status_details === 'role_based_catch_all' || el.status_details === 'role_based')) ? 
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--verified'>
                                                        <MarkEmailReadIcon /> Role-based
                                                    </span> 
                                                    </Tooltip>
                                                    :
                                                    (el.email_status === 'do_not_mail' && (el.status_details === 'global_suppression' || el.status_details === 'toxic' || el.status_details === 'possible_trap')) ? 
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--inactive'>
                                                        <DoNotDisturbIcon /> Do not use
                                                    </span> 
                                                    </Tooltip>
                                                    :
                                                    (el.email_status === 'do_not_mail' && (el.status_details === 'mx_forward' || el.status_details === 'disposable')) ? 
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--verified--yellow'>
                                                        <MarkEmailReadIcon /> Redirect?
                                                    </span> 
                                                    </Tooltip>
                                                    :
                                                    el.email_status === 'not_checked' ? 
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--notchecked'>
                                                        <HourglassTopIcon /> Under review
                                                    </span>
                                                    </Tooltip> 
                                                    :
                                                    el.email_status === 'catch-all' ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--verified--yellow'>
                                                        <MarkEmailReadIcon /> Catch-all
                                                    </span> 
                                                    </Tooltip> 
                                                    :
                                                    el.email_status === 'abuse' ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--verified--yellow'>
                                                        <MarkEmailReadIcon /> Abuse
                                                    </span> 
                                                    </Tooltip>
                                                    : 
                                                    (el.email_status === 'unknown' && (el.status_details === 'antispam_system' || el.status_details === 'greylisted')) ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--verified--yellow'>
                                                        <MarkEmailReadIcon /> Antispam
                                                    </span> 
                                                    </Tooltip>
                                                    :
                                                    (el.email_status === 'unknown' && (el.status_details === 'failed_smtp_connection' || el.status_details === 'mail_server_temporary_error')) ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--notchecked'>
                                                        <HourglassTopIcon /> Unknown
                                                    </span> 
                                                    </Tooltip>
                                                    : 
                                                    el.email_status === 'unknown' ?
                                                    <Tooltip placement="left" size='small' title={
                                                        <span className='info-span'>
                                                        Email checked on {convertDate(el.status_date)}
                                                        </span>} 
                                                    >
                                                    <span className='emails__status emails__status--notchecked'>
                                                        <HourglassTopIcon /> Unknown
                                                    </span> 
                                                    </Tooltip>
                                                    : ''
                                                    }
                                                </div>


                                                <IconButton onClick={()=>copyToClipboard(el.email,'email')} color="primary">
                                                    <ContentCopyIcon />
                                                </IconButton>

                                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            
                                                <span className={'emails__text-email'}>
                                                    {el.email}
                                                </span>

                                            <span id={'success-span-' + item.id} className='email-save-span'>
                                                Copied to clipboard
                                            </span>
                                            
                                        </div>
                                        );
                                    })}

                                </div>

                                : (dataReceived && emails?.length === 0) ?
                                <span className='single-bottom-info__no-info no-info--email'>
                                <EmailIcon />
                                No emails found
                                </span>
                                : null
                                }
                            </div>
                            : 
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            }

                            {dataReceived ?
                                <div className='company__section company__section-phones fade-appear'>
                                    <div className='company__phones-h company__heading'>
                                    Phones
                                    </div>  
                                    {Array.isArray(phones) && phones.length > 0 && phones.some(phoneDataState => phoneDataState.isvalid.includes('Valid')) ?
                                    <div className={'btm-tel__array'}>
                                        <div className={'btm-tel__array'}>
                                            <PhoneData 
                                            convertDate={convertDate}
                                            itemid={item.id}
                                            phones={phones}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <span className='single-bottom-info__no-info no-info--phone'>
                                    <LocalPhoneIcon />
                                    No phones found
                                    </span>
                                    }

                                </div>
                            : 
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            }

                            {dataReceived && companyData?.persons !== '' && companyData?.persons !== 0 && companyData?.persons !== '0' ?
                            <div className='company__section company__section-persons fade-appear'>
                                
                                <div className='company__persons-h company__heading'>
                                    Persons
                                </div>

                                <div className={'persons'}>
                                    {companyData?.persons?.split(',')?.map((person,index)=>{
                                        return (
                                            <div className='persons__row' key={id+'persons'+index}>
                                                
                                                <div className='persons__container-h'>

                                                    <span className='persons__name'>

                                                        <ContactsSharpIcon />{person?.split(':')[0]}

                                                    </span>

                                                    {!person?.split(':')[1]?.includes('linkedin') && !person?.split(':')[1]?.includes('@') && !person?.split(':')[1]?.includes('No') && !person?.split(':')[1]?.includes('+') && (person?.split(':')[1] !== undefined && person?.split(':')[1] !== 'undefined') ?
                                                    <span className='persons__title'>
                                                        {person?.split(':')[1]}
                                                    </span>
                                                    : null}

                                                    {person?.includes('@') || person?.includes('+') || person?.includes('linkedin') ? 
                                                        
                                                        <Button 
                                                            className='persons__open'
                                                            color="primary_grey_light"
                                                            size="small"
                                                            variant="contained"
                                                            onClick={()=>openPerson(id,index)}
                                                            endIcon={<ArrowForwardIosIcon />}
                                                            sx={{
                                                                borderRadius: "2px",
                                                                paddingLeft: "8px",
                                                                paddingRight: "8px",
                                                                height: "23px",
                                                                marginLeft: "20px",
                                                                textTransform: "none",
                                                                fontSize: "0.76rem",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",

                                                            }}
                                                            >
                                                            Details
                                                        </Button>

                                                    : null}

                                                </div>

                                                <Collapse className="persons__list" in={openedPerson === ('c' + id + 'p' + index)} collapsedSize={0}> 
                                                <>
                                                {person?.split(':')
                                                .filter((field, index) => {
                                                    return index > 1 || (index <= 1 && (field.includes('linkedin') || field.includes('@') || field.includes('+')));
                                                })
                                                .map((field,index)=>{
                                                    return(
                                                        <div className="test" key={'person-key-' + index}>
                                                            {field?.includes('linkedin') 
                                                            ? 
                                                            <div key={'linkedin' + id+index} className='persons__column-linkedin'>
                                                            <Button 
                                                            href={'https://linkedin' + field?.split('linkedin')[1]}
                                                            target="_blank"
                                                            className='persons__linkedin'
                                                            color="blue_button_link"
                                                            size="small"
                                                            variant="contained" 
                                                            startIcon={<LinkedInIcon />}
                                                            sx={{
                                                                borderRadius: "2px",
                                                                textTransform: "none"
                                                            }}
                                                            >
                                                            Linkedin
                                                            </Button>
                                                            </div>

                                                            : (field?.includes('@') && !field?.includes('linkedin'))
                                                            ?
                                                            <div key={'email' + id+index} className='persons__column-email'>
                                                            <EmailIcon />{field}                                                
                                                            <IconButton className='persons__email' onClick={()=>copyToClipboard(field,'persons')} color="primary">
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                            <span id={'success-span-person-' + field?.split('@')[0]} className='email-save-span email-save-span--persons'>
                                                                Copied to clipboard
                                                            </span>
                                                            </div>

                                                            : (field?.includes('+') && !field?.includes('linkedin'))
                                                            ? 

                                                            <div key={'phone' + id+index} className='persons__column-phone'>
                                                            
                                                            <LocalPhoneIcon />{field}
                                                            </div>

                                                            : null
                                                            }
                                                        </div>
                                                    );
                                                })}
                                                </>
                                                </Collapse>

                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                            : !dataReceived ?
                            <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                                <span className='wide-loader-item wide-loader-item--full'>          
                                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                        <LinearProgress 
                                        color="primary_grey_light_light" 
                                        sx={{
                                            height: '25px',
                                            borderRadius: '2px',
                                        }}
                                        />
                                    </Stack>
                                </span>   
                            </Fade>
                            : null
                            }

                    </div>

                    <div className='container-bottom__right'>
                        {dataReceived ?

                            <div className='distributor-notes fade-appear'>

                                <TextField
                                id={'outlined-multiline-static-' + id}
                                className='distributor-notes__input'
                                label="Your private notes"
                                multiline
                                rows={6}
                                onChange={(event)=>recordNote(event)}
                                // value={item.notes}
                                value={noteValue}
                                />

                                <span className='distributor-notes__h'>
                                    You can edit this description
                                </span>

                                <div className={'distributor-notes__notification' + 
                                (noteSaved ? ' distributor-notes__notification--visible' : '')}>
                                    Saved
                                </div>


                                <Button 
                                onClick={saveNote} 
                                size={'small'}
                                color={'primary_grey_light'}
                                variant='contained'
                                className='distributor-notes__save-btn'
                                startIcon={<SaveIcon />}>
                                    Save
                                </Button>

                            </div>
                        :
                        <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                            <span className='wide-loader-item wide-loader-item--full--block'>          
                                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                    <LinearProgress 
                                    color="primary_grey_light_light" 
                                    sx={{
                                        height: '25px',
                                        borderRadius: '2px',
                                    }}
                                    />
                                </Stack>
                            </span>   
                        </Fade>}
                        
                        {dataReceived ?
                        <>
                        <div className='company__action--folders fade-appear'>
                            <FoldersAction
                            // id={id}
                            item={companyData} 
                            typeActionButton={2} 
                            // setUserDownloadIds={setUserDownloadIds}
                            // userCounter={userCounter}
                            // downloaded={downloaded}
                            // setUserCounter={setUserCounter}
                            userFolders={userFolders}
                            setUserFolders={setUserFolders}
                            getCookie={getCookie}
                            // expanded={expanded}
                            // expandActionItem={expandActionItem}
                            // pageNumber={pageNumber}
                            // userPlan={userPlan}
                            // currentFolder={currentFolder}
                            />
                        </div>

                        <div className={'fade-appear company__btm-folders' + (arrayFoldersItem?.length < 1 ? ' company__btm-folders--empty' : '')}>
                            
                            {arrayFoldersItem?.length > 0 ?
                                arrayFoldersItem?.map((itemD,index)=>{
                                    return(
                                        <span key={'folder' + index}>
                                            {findIcon(itemD)}
                                            {itemD.name}
                                            {arrayFoldersItem.length > index && (index + 1) < arrayFoldersItem.length && arrayFoldersItem.length !== 1 ? ', ' : ''}
                                        </span>
                                    );
                                })
                             : <><FaFolder/>Not added to folders</>}

                        </div>
                        
                        <div className='company__btm-actions fade-appear'>
                            <Button 
                            onClick={exportThisCompany}
                            size={'small'}
                            color={'primary_grey_light'}
                            variant='contained'
                            className=''
                            startIcon={<DownloadIcon />}
                            sx={{
                                'textTransform': 'none',
                                'borderRadius': '2px',
                                'fontSize': '0.86rem',
                            }}
                            >
                                Download Company
                            </Button>

                        </div>


                        <div className='company__btm-report fade-appear'>
                        {!reported ? 
                        <Button variant="text" endIcon={<NotificationsActiveIcon/>} onClick={reportInnacuracy}>
                        Report Inaccuracy
                        </Button>
                        : reported === 'error' ?
                        <Button disabled variant="text">
                        Error
                        </Button>
                        :                        
                        <Button disabled endIcon={<NotificationImportantIcon/>} variant="text">
                        Reported
                        </Button>
                        }
                        
                        </div>
                        </>
                        :                        
                        <Fade unmountOnExit={true} in={true} timeout={{ enter: 400, exit: 200 }}>
                        <span className='wide-loader-item wide-loader-item--full wide-loader-item--double'>          
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                                <LinearProgress 
                                color="primary_grey_light_light" 
                                sx={{
                                    height: '25px',
                                    borderRadius: '2px',
                                }}
                                />
                                <LinearProgress 
                                color="primary_grey_light_light" 
                                sx={{
                                    height: '25px',
                                    borderRadius: '2px',
                                }}
                                />
                            </Stack>
                        </span> 
                        </Fade>
                        }

                    </div>

                </>
                
            </Collapse>

        </div>
    );
}